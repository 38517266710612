import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"

const TrustPilotWidgetSliderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${({ page }) =>
    page === "home" ? "rgba(255, 255, 255, 0.8);" : "transparent"};
  margin: 20px 0px;
  border-radius: ${({ page }) =>
    page === "home" ? "50px 0px 50px 0px" : "0px"};
  padding: ${({ page }) => (page === "home" ? "25px 20px" : "0px")};

  @media (max-width: 550px) {
    padding: ${({ page }) => (page === "home" ? "25px 0px" : "0px")};
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0px auto;

  iframe {
    margin: 0px;
  }
`

const TrustPilotSectionTitle = styled.h2`
  width: 100%;
  padding: 0px 20px;
  font-size: 40px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  text-align: center;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    font-size: 36px;
    text-align: left;
  }
`

const TrustPilotWidgetSlider = ({ intl, tags, page, id }) => {
  const trustPilotScript = () => {
    try {
      const script = document.createElement("script")
      script.src =
        "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      script.async = true
      document.head.appendChild(script)
      const trustbox = document.getElementById("trustbox")
      window.Trustpilot.loadFromElement(trustbox)
      return () => {
        document.head.removeChild(script)
      }
    } catch (e) {
      console.error(
        "An error occured while trying to load Trustpilot script. Try to disable your ad blocker an refresh the page."
      )
    }
  }

  useEffect(() => {
    isBrowser && trustPilotScript()
  }, [])

  return (
    <TrustPilotWidgetSliderWrapper page={page} id={id}>
      {intl.locale === "fr" && (
        <TrustPilotSectionTitle>Nos clients témoignent</TrustPilotSectionTitle>
      )}
      <Container>
        {intl.locale === "fr" ? (
          <div
            id="trustbox"
            className="trustpilot-widget"
            data-locale="fr-FR"
            data-template-id="54ad5defc6454f065c28af8b"
            data-businessunit-id="5a6f410446b1cf00016307a8"
            data-style-height="240px"
            data-style-width="100%"
            data-theme="light"
            data-tags={tags}
            data-stars="4,5"
            data-review-languages="fr"
            data-no-reviews="hide"
            data-scroll-to-list="true"
            data-allow-robots="true"
          >
            <a
              href="https://fr.trustpilot.com/review/www.percko.com"
              target="_blank"
              rel="noopener"
            >
              Trustpilot
            </a>
          </div>
        ) : (
          <div
            id="trustbox"
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="54ad5defc6454f065c28af8b"
            data-businessunit-id="5a6f410446b1cf00016307a8"
            data-style-height="240px"
            data-style-width="100%"
            data-theme="light"
            data-tags={tags}
            data-stars="4,5"
            data-review-languages="en"
            data-no-reviews="hide"
            data-scroll-to-list="true"
            data-allow-robots="true"
          >
            <a
              href="https://uk.trustpilot.com/review/www.percko.com"
              target="_blank"
              rel="noopener"
            >
              Trustpilot
            </a>
          </div>
        )}
      </Container>
    </TrustPilotWidgetSliderWrapper>
  )
}

export default injectIntl(TrustPilotWidgetSlider)
